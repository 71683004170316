:root {
  --color-magenta: #e43366;
  --color-magenta-rgb: 228 51 102;
  --color-magenta-secondary: #bb294b;
  --color-magenta-secondary-rgb: 187 41 75;
  --color-white: #fff;
  --color-white-rgb: 255 255 255;
  --color-light: #ebebeb;
  --color-light-rgb: 235 235 235;
  --color-black: #000;
  --color-black-rgb: 0 0 0;
  --color-grey: #797979;
  --color-grey-rgb: 121 121 121;
  --color-violet: #210033;
  --color-line-rgb: 102 102 102;
}

@font-face {
  font-family: Overpass Regular;
  src: url("Overpass-Regular.236aedd2.woff2") format("woff2");
}

:root {
  letter-spacing: .1px;
  color: var(--color-white);
  font-family: Overpass Regular, Arial, sans-serif;
  font-size: 16px;
}

html, body {
  width: 100%;
  min-width: 360px;
  margin: 0;
  padding: 0;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--color-magenta) rgb(var(--color-magenta-rgb) / 50%);
  background: #190027 url("background-dark.8f82bb5a.svg") center / cover no-repeat fixed;
  font-size: .875rem;
  line-height: 1.125rem;
}

.logo {
  margin: 3rem 1.5rem 2rem calc(100% - 207px - 4.5rem);
  display: inline-block;
}

.hexagon1 {
  --hexagon-image: url("phones.e846e35e.jpg");
  --hexagon-content-top: "Share";
  --hexagon-content-center: "your life,";
  --hexagon-content-bottom: "not your personal data.";
}

.hexagon2 {
  --hexagon-image: url("earth.ce200df4.jpg");
  --hexagon-content-top: "Decide";
  --hexagon-content-center: "who sees";
  --hexagon-content-bottom: "your private information.";
}

.hexagon3 {
  --hexagon-image: url("keyboard.ceae0e78.jpg");
  --hexagon-content-top: "Regain";
  --hexagon-content-center: "control";
  --hexagon-content-bottom: "over your digital identity.";
}

@media screen and (width >= 800px) {
  .logo {
    margin-left: calc(50% - 207px - 1.5rem);
  }
}

p {
  margin-block: 1.25rem;
}

@media screen and (width >= 800px) {
  .right, .left {
    width: 50%;
    max-width: 1000px;
  }
}

.left {
  box-sizing: border-box;
  margin-left: auto;
  padding-inline: 3rem;
}

@media screen and (width >= 800px) {
  .left {
    margin-right: 50%;
    padding-right: 0;
  }
}

.right {
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 4rem;
  left: 50%;
}

@media screen and (width >= 800px) {
  .right {
    display: flex;
  }
}

.leftContainer {
  box-sizing: border-box;
  background: linear-gradient( 180deg, rgb(var(--color-white-rgb) / 10%) 0%, rgb(var(--color-black-rgb) / 12%) 100% ), #20013199;
  box-shadow: -2px -2px 9px 0 rgb(var(--color-white-rgb) / 20%), 2px 2px 9px 0 rgb(var(--color-black-rgb) / 20%);
  border-radius: .625rem;
  margin-bottom: 5rem;
  padding: 2rem;
}

@media screen and (width >= 800px) {
  .leftContainer {
    min-height: calc(100vh - 10rem);
    margin-bottom: auto;
  }
}

.rightContainer {
  filter: drop-shadow(-3px -3px 4px rgb(var(--color-white-rgb) / 30%) );
  width: 70%;
  aspect-ratio: 545 / 570;
  position: relative;
}

.rightContainer:before {
  content: "";
  background: var(--hexagon-image) no-repeat center / cover;
  clip-path: polygon(40.17% .37%, 89.98% 17.02%, 99.82% 66.58%, 59.83% 99.51%, 10.02% 82.86%, .18% 33.3%);
  width: 100%;
  height: 100%;
  padding-top: 3rem;
  display: block;
}

.rightHeadline {
  flex-wrap: wrap;
  align-items: baseline;
  margin-left: 10%;
  font-size: .5rem;
  font-weight: normal;
  line-height: 1.25;
  display: flex;
  position: absolute;
  top: 10%;
  left: 0;
}

.heading {
  margin: 0;
  font-size: 1.2rem;
  font-weight: normal;
  line-height: 1.25;
}

.buttonsLine {
  text-align: center;
}

.ctaButton {
  cursor: pointer;
  height: 2rem;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-magenta);
  box-shadow: 2px 2px 3px 1px rgb(var(--color-black-rgb) / 15%), -2px -2px 3px 1px rgb(var(--color-white-rgb) / 30%);
  border: none;
  border-radius: 1rem;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: .2rem auto;
  padding: 0 3rem;
  font-family: inherit;
  font-size: .875rem;
  text-decoration: none;
  display: inline-flex;
}

@media screen and (width >= 950px) {
  .rightHeadline {
    font-size: .6rem;
  }
}

@media screen and (width >= 1200px) {
  .rightHeadline {
    font-size: .7rem;
  }
}

@media screen and (width >= 1300px) {
  .rightHeadline {
    font-size: .8rem;
  }
}

@media screen and (width >= 1400px) {
  .rightHeadline {
    font-size: .9rem;
  }
}

@media screen and (width >= 1500px) {
  .rightHeadline {
    font-size: 1rem;
  }
}

.rightHeadlineTop {
  font-size: 4em;
  line-height: .8;
}

.rightHeadlineTop:after {
  content: var(--hexagon-content-top);
}

.rightHeadlineCenter {
  margin-left: .5em;
  margin-right: auto;
  font-size: 1.5em;
}

.rightHeadlineCenter:after {
  content: var(--hexagon-content-center);
}

.rightHeadlineBottom {
  margin-left: 5em;
  font-size: 1.5em;
}

.rightHeadlineBottom:after {
  content: var(--hexagon-content-bottom);
}

::-webkit-scrollbar {
  width: .625rem;
  display: block;
}

::-webkit-scrollbar-track {
  background: rgb(var(--color-magenta-rgb) / 50%);
}

::-webkit-scrollbar-thumb {
  background: var(--color-magenta);
  border-radius: .375rem;
}

@supports not (aspect-ratio: 545 / 570) {
  .rightContainer:before {
    padding-bottom: 100%;
  }
}

.footer {
  width: 100%;
  min-height: 2.125rem;
  background: var(--color-violet);
  border-top: 1px solid var(--color-magenta);
  place-content: center;
  display: flex;
  position: fixed;
  bottom: 0;
}

.content {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 5rem;
  line-height: 2.25rem;
  display: flex;
}

@media screen and (width >= 700px) {
  .content {
    flex-direction: row;
  }
}

.navMenu {
  width: 100vw;
  border-bottom: 1px solid rgb(var(--color-line-rgb) / 75%);
  justify-content: center;
  gap: .625rem;
  font-size: .875rem;
  display: flex;
}

@media screen and (width >= 700px) {
  .navMenu {
    width: auto;
    border: 0;
  }
}

.navLink {
  color: var(--color-white);
  text-decoration: none;
}

.navLinkActive.navLink {
  opacity: .5;
}

.copyright {
  color: var(--color-white);
  background: url("built-on-KILT.7796ce9a.svg") right top .3125rem no-repeat;
  margin: 0;
  padding: 0 5.625rem 0 0;
  font-size: .625rem;
}

.leftContainer404 {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.leftHeadline {
  width: 70%;
  background: url("404.b7b04b7b.svg") top / contain no-repeat;
  margin: 0;
  padding-top: 30%;
  font-size: 7vw;
}

@media screen and (width >= 800px) {
  .leftHeadline {
    font-size: 2.125rem;
  }
}

@media screen and (width >= 950px) {
  .leftHeadline {
    font-size: 2.625rem;
  }
}

@media screen and (width >= 1200px) {
  .leftHeadline {
    font-size: 3.25rem;
  }
}

@media screen and (width >= 1300px) {
  .leftHeadline {
    font-size: 3.625rem;
  }
}

@media screen and (width >= 1400px) {
  .leftHeadline {
    font-size: 4rem;
  }
}

@media screen and (width >= 1500px) {
  .leftHeadline {
    font-size: 4.25rem;
  }
}

.buttonPrimary {
  height: 2rem;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-magenta);
  box-shadow: 2px 2px 3px 1px rgb(var(--color-black-rgb) / 15%), -2px -2px 3px 1px rgb(var(--color-white-rgb) / 30%);
  border-radius: 1rem;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin-top: 2.5rem;
  padding: 0 1.75rem;
  font-size: .875rem;
  text-decoration: none;
  display: inline-flex;
}

.rightContainer404:before {
  background: url("robot.d2c96dc6.jpg") center / 120% no-repeat;
}

.rightHeadline404 {
  top: 0;
  left: 0;
}

.rightHeadline404Top {
  padding-left: 3em;
  font-size: 1.5em;
}

.rightHeadline404Center {
  font-size: 3em;
}

.rightHeadline404Bottom {
  padding-left: 6.5em;
  font-size: 1.5em;
}

/*# sourceMappingURL=404.6f120a52.css.map */
