@import url('../components/Theme/Theme.module.css');
@import url('../components/Typography/Typography.module.css');

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 360px;
}

body {
  background: #190027 url('../images/background-dark.svg') no-repeat fixed
    center/cover;
  scrollbar-width: thin;
  scrollbar-color: var(--color-magenta) rgb(var(--color-magenta-rgb) / 50%);
  font-size: 0.875rem;
  line-height: 1.125rem;
}

.logo {
  display: inline-block;
  margin: 3rem 1.5rem 2rem calc(100% - 207px - 4.5rem);
}

.hexagon1 {
  --hexagon-image: url('/src/frontend/images/hexagons/phones.jpg');
  --hexagon-content-top: 'Share';
  --hexagon-content-center: 'your life,';
  --hexagon-content-bottom: 'not your personal data.';
}

.hexagon2 {
  --hexagon-image: url('/src/frontend/images/hexagons/earth.jpg');
  --hexagon-content-top: 'Decide';
  --hexagon-content-center: 'who sees';
  --hexagon-content-bottom: 'your private information.';
}

.hexagon3 {
  --hexagon-image: url('/src/frontend/images/hexagons/keyboard.jpg');
  --hexagon-content-top: 'Regain';
  --hexagon-content-center: 'control';
  --hexagon-content-bottom: 'over your digital identity.';
}

@media screen and (width >= 800px) {
  .logo {
    margin-left: calc(50% - 207px - 1.5rem);
  }
}

p {
  margin-block: 1.25rem;
}

@media screen and (width >= 800px) {
  .right,
  .left {
    width: 50%;
    max-width: 1000px;
  }
}

.left {
  margin-left: auto;
  box-sizing: border-box;
  padding-inline: 3rem;
}

@media screen and (width >= 800px) {
  .left {
    margin-right: 50%;
    padding-right: 0;
  }
}

.right {
  position: fixed;
  left: 50%;
  top: 4rem;
  display: none;
  align-items: center;
  justify-content: center;
}

@media screen and (width >= 800px) {
  .right {
    display: flex;
  }
}

.leftContainer {
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: linear-gradient(
      180deg,
      rgb(var(--color-white-rgb) / 10%) 0%,
      rgb(var(--color-black-rgb) / 12%) 100%
    ),
    rgb(32 1 49 / 60%);
  padding: 2rem;
  box-shadow:
    -2px -2px 9px 0 rgb(var(--color-white-rgb) / 20%),
    2px 2px 9px 0 rgb(var(--color-black-rgb) / 20%);
  margin-bottom: 5rem;
}

@media screen and (width >= 800px) {
  .leftContainer {
    min-height: calc(100vh - 10rem);
    margin-bottom: auto;
  }
}

.rightContainer {
  filter: drop-shadow(-3px -3px 4px rgb(var(--color-white-rgb) / 30%));
  width: 70%;
  aspect-ratio: 545 / 570;
  position: relative;
}

.rightContainer::before {
  content: '';
  background: var(--hexagon-image) no-repeat center/cover;
  clip-path: polygon(
    40.17% 0.37%,
    89.98% 17.02%,
    99.82% 66.58%,
    59.83% 99.51%,
    10.02% 82.86%,
    0.18% 33.3%
  );
  width: 100%;
  height: 100%;
  display: block;
  padding-top: 3rem;
}

.rightHeadline {
  position: absolute;
  top: 10%;
  left: 0;
  margin-left: 10%;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  font-size: 0.5rem;
  line-height: 1.25;
  font-weight: normal;
}

.heading {
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.25;
  margin: 0;
}

.buttonsLine {
  text-align: center;
}

.ctaButton {
  border: none;
  cursor: pointer;
  font-family: inherit;
  height: 2rem;
  padding: 0 3rem;
  align-self: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  font-size: 0.875rem;
  text-transform: uppercase;
  color: var(--color-white);
  background-color: var(--color-magenta);
  box-shadow:
    2px 2px 3px 1px rgb(var(--color-black-rgb) / 15%),
    -2px -2px 3px 1px rgb(var(--color-white-rgb) / 30%);
  text-decoration: none;
  margin: 0.2rem auto;
}

@media screen and (width >= 950px) {
  .rightHeadline {
    font-size: 0.6rem;
  }
}

@media screen and (width >= 1200px) {
  .rightHeadline {
    font-size: 0.7rem;
  }
}

@media screen and (width >= 1300px) {
  .rightHeadline {
    font-size: 0.8rem;
  }
}

@media screen and (width >= 1400px) {
  .rightHeadline {
    font-size: 0.9rem;
  }
}

@media screen and (width >= 1500px) {
  .rightHeadline {
    font-size: 1rem;
  }
}

.rightHeadlineTop {
  font-size: 4em;
  line-height: 0.8;
}

.rightHeadlineTop::after {
  content: var(--hexagon-content-top);
}

.rightHeadlineCenter {
  font-size: 1.5em;
  margin-left: 0.5em;
  margin-right: auto;
}

.rightHeadlineCenter::after {
  content: var(--hexagon-content-center);
}

.rightHeadlineBottom {
  font-size: 1.5em;
  margin-left: 5em;
}

.rightHeadlineBottom::after {
  content: var(--hexagon-content-bottom);
}

::-webkit-scrollbar {
  display: block;
  width: 0.625rem;
}

::-webkit-scrollbar-track {
  background: rgb(var(--color-magenta-rgb) / 50%);
}

::-webkit-scrollbar-thumb {
  background: var(--color-magenta);
  border-radius: 0.375rem;
}

@supports not (aspect-ratio: 545 / 570) {
  .rightContainer::before {
    padding-bottom: 100%;
  }
}

.footer {
  width: 100%;
  display: flex;
  position: fixed;
  place-content: center;
  min-height: 2.125rem;
  background: var(--color-violet);
  bottom: 0;
  border-top: 1px solid var(--color-magenta);
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  line-height: 2.25rem;
  margin: 0 5rem;
}

@media screen and (width >= 700px) {
  .content {
    flex-direction: row;
  }
}

.navMenu {
  display: flex;
  width: 100vw;
  justify-content: center;
  gap: 0.625rem;
  border-bottom: 1px solid rgb(var(--color-line-rgb) / 75%);
  font-size: 0.875rem;
}

@media screen and (width >= 700px) {
  .navMenu {
    width: auto;
    border: 0;
  }
}

.navLink {
  color: var(--color-white);
  text-decoration: none;
}

.navLinkActive.navLink {
  opacity: 0.5;
}

.copyright {
  color: var(--color-white);
  padding: 0 calc(90rem / 16) 0 0;
  font-size: 0.625rem;
  margin: 0;
  background: url('../images/built-on-KILT.svg') no-repeat top calc(5rem / 16)
    right;
}
