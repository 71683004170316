@font-face {
  font-family: 'Overpass Regular';
  src: url('Overpass-Regular.woff2') format('woff2');
}

:root {
  font-family: 'Overpass Regular', Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.1px;
  color: var(--color-white);
}
